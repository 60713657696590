@import '@socialbrothers/assets/styles/abstracts';

.Footer {
	margin-top: auto;
	background-color: color(white);
	font-size: $font-size-xs;
	line-height: 70px;
	text-align: center;
}

.Spinner {
	position: fixed;
	right: 15px;
	bottom: 15px;
	padding: 20px;
	border-radius: 50%;

	background-color: color(white);
}
