.fade {
	&-enter {
		opacity: 0.01;
	}

	&-enter-active {
		@include transition(opacity);
		opacity: 1;
	}

	&-enter-done {
		opacity: 1;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		@include transition(opacity);
		opacity: 0.01;
	}

	&-exit-done {
		display: none;
	}
}

.slideLeft {
	&-enter {
		transform: translateX(-100%);
	}

	&-enter-active {
		@include transition(transform);
		transform: translateX(0);
	}

	&-enter-done {
		transform: translateX(0);
	}

	&-exit {
		transform: translateX(0);
	}

	&-exit-active {
		@include transition(transform);
		transform: translateX(-100%);
	}

	&-exit-done {
		display: none !important;
	}
}

.slideTop {
	&-enter {
		transform: translateY(-100%);
	}

	&-enter-active {
		@include transition(transform);
		transform: translateY(0);
	}

	&-enter-done {
		transform: translateY(0);
	}

	&-exit {
		transform: translateY(0);
	}

	&-exit-active {
		@include transition(transform);
		transform: translateY(-100%);
	}

	&-exit-done {
		display: none;
	}
}
