@import '@socialbrothers/assets/styles/abstracts';

.Card {
	margin-bottom: spacing();
	border: 1px solid color(gray, 300);
	border-radius: $border-radius;
	box-shadow: $box-shadow;
}

.Header {
	position: relative;
	padding: px(1.5) $default-padding;
	border-bottom: 1px solid color(gray, 300);
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	background-color: color(gray, 100);
	color: color(primary);

	font-weight: $font-weight-bold;
}

.Spinner {
	position: absolute;
	top: 50%;
	right: $default-padding;
	transform: translateY(-50%);
}

.Body {
	padding: $default-padding;
	border-bottom-right-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
	background-color: color(white);

	> *:last-child {
		margin-bottom: 0 !important;
	}
}
