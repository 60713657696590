@import '@socialbrothers/assets/styles/abstracts';

.DateInput {
	position: relative;
	width: 100%;

	li {
		&:before {
			content: normal !important;
		}
	}

	&__Icon {
		position: absolute;
		top: 50%;
		right: px(2);
		transform: translateY(-50%);
		pointer-events: none;
	}
}
