@import '@socialbrothers/assets/styles/abstracts';

.Progress {
	width: 100%;

	&.SizeLG {
		margin-bottom: $default-margin;

		.Bar {
			height: 16px;
		}
	}
}

.Header {
	display: flex;
	margin-bottom: px(0.5);
	font-size: $font-size-xs;
	font-weight: $font-weight-bold;
}

.Value {
	margin-left: auto;
}

.Bar {
	height: 8px;
	overflow: hidden;
	border-radius: $border-radius;
	background-color: color(gray, 300);
}

.Percentage {
	max-width: 100%;
	@include transition(width);
	height: 100%;
}
