@import '@socialbrothers/assets/styles/abstracts';

.react-datepicker {
	border-radius: $input-radius !important;
	border-color: $input-border-color !important;
	font-family: $font-family-sans-serif !important;

	&-wrapper {
		width: 100%;
	}

	&-popper {
		z-index: 9999 !important;
		box-shadow: $box-shadow;
	}

	&__triangle {
		display: none;
	}

	&__header {
		border-radius: $input-radius !important;
		border-bottom-color: $input-border-color !important;

		background-color: $input-background-color !important;

		&__dropdown {
			display: flex;
			padding: 0 12px;

			> div {
				flex-grow: 1;

				select {
					padding: px(0.5) px(1);

					background-color: color(white);

					font-size: 13px;
				}
			}
		}
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__month-text--keyboard-selected,
	.react-datepicker__quarter-text--keyboard-selected,
	.react-datepicker__year-text--keyboard-selected {
		background-color: color(primary);
	}

	.react-datepicker__month-select,
	.react-datepicker__year-select {
		border-color: $input-border-color !important;
	}
}
