@import '@socialbrothers/assets/styles/styles.scss';

.Wrapper {
  width: 100%;
}

.TableHeader {
  display: flex;
  align-items: flex-start;
  margin-bottom: $default-margin;

  &__Search {
    width: 100%;
    margin-right: $default-margin;
  }

  &__Add {
    margin-left: auto;
  }
}

.TableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $default-margin;

  .PerPage {
    display: flex;
    align-items: center;
    white-space: nowrap;

    select {
      margin: 0 px(1);
      padding: px(1);
      text-align: center;
    }

    &__Current {
      margin-left: px(3);
    }
  }

  .Results {
    margin-left: px(2);
    opacity: 0.5;
  }

  .Pagination {
    margin-left: auto;
  }

  @include media-breakpoint-only(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;

    .Pagination {
      margin-bottom: $default-margin;
    }
  }
}

.ResourceTable {
  &__ExportButton {
    display: block;
    margin-bottom: px(1);
    margin-left: auto;
    opacity: 1;

    @include transition(opacity, sm);

    &--Hidden {
      opacity: 0;
    }
  }
}
