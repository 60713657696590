@import '@socialbrothers/assets/styles/abstracts';

.FileInput {
  width: 100%;

  input[type='file'] {
    display: none;
  }

  &__Input {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-bottom: 0;
    border-radius: $border-radius;
  }

  &__Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -1px -1px -1px 0;
    padding: $button-padding;
    border-radius: 0 $input-border-radius $input-border-radius 0;
    background-color: color(primary);
    color: color(white);
    vertical-align: middle;
    white-space: nowrap;
  }

  &__Name {
    padding: px(1.75) px(2);
    overflow: hidden;
    line-height: 1.25;
  }

  .Preview {
    padding: px(1.75) px(2);
    border-top: 1px solid $input-border-color;

    &__Item {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: $input-border-radius;
      background-color: $input-border-color;
    }

    &__Link {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__File {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: $input-border-radius;
      color: color(primary);

      font-size: $font-size-sm;
      object-fit: contain;
      object-position: center;
    }

    &__PDF {
      font-size: 50px;
      color: color(primary);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__Delete {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
      border-radius: 50%;
    }
  }
}
