@import '@socialbrothers/assets/styles/abstracts';

.Breadcrumb {
	display: flex;
	flex-direction: row;
}

.Item {
	margin: 0;
	padding: 0;

	&::before {
		display: none;
	}

	&:not(:last-child)::after {
		content: '\f054';
		display: inline-block;
		width: 30px;
		color: color(gray, 500);
		font-family: $font-family-icons;
		font-size: $font-size-xs;
		text-align: center;
	}
}

.Link {
	color: $body-color;
	font-size: $font-size-sm;

	&:hover {
		text-decoration: underline;
	}

	&.Current {
		color: color(primary);
		font-weight: $font-weight-bold;

		&:hover {
			text-decoration: none;
		}
	}
}
