@import '@socialbrothers/assets/styles/abstracts';

.ReadOnlyField {
  position: relative;
  flex: 1 1;
  margin-bottom: px(3);
  background-color: color(white);

  &__Fieldset {
    margin: 0;
    padding: 0;
    > div {
      border: none;
      background-color: transparent !important;
    }
  }

  &__Error {
    color: color(danger);
  }

  & p {
    margin-bottom: 0;
    padding: px(1) px(2);
  }

  & a {
    display: inline-block;
    margin: px(1) px(2);
  }
}
