@import '@socialbrothers/assets/styles/abstracts';

.Group {
	margin-bottom: px(3);
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;

	&__Title {
		margin-bottom: 0;
		padding: px(0.75) px(2);
		background-color: $input-border-color;
	}

	&__Fields {
		padding: px(2);

		> *:last-child {
			margin-bottom: 0;
		}
	}
}
