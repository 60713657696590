@each $bp in map-keys($grid-breakpoints) {
	.hidden-#{$bp}-up {
		@include media-breakpoint-up($bp) {
			display: none !important;
		}
	}

	.hidden-#{$bp}-down {
		@include media-breakpoint-down($bp) {
			display: none !important;
		}
	}

	.visible-#{$bp}-only {
		display: none !important;

		@include media-breakpoint-only($bp) {
			display: block !important;
		}
	}
}

.p-relative {
	position: relative;
}
