@each $color, $tint in $theme-colors {
	@each $i, $value in $tint {
		@if ($i == "default") {
			.color-#{$color} {
				color: $value !important;
			}

			.background-#{$color} {
				background-color: $value !important;
			}

			.border-top-#{$color} {
				border-top: 3px solid $value !important;
			}

			.border-left-#{$color} {
				border-left: 3px solid $value !important;
			}

			.border-bottom-#{$color} {
				border-bottom: 3px solid $value !important;
			}

			.border-right-#{$color} {
				border-right: 3px solid $value !important;
			}
		}
	}
}
