.Toastify {
	&__toast-container {
		padding: 0 !important;

		@media only screen and (max-width: 480px) {
			padding: 15px !important;
		}
	}

	&__toast {
		display: flex;
		align-items: flex-start;
		margin-bottom: px(1) !important;
		border-radius: $border-radius !important;
		box-shadow: $box-shadow !important;
	}
}

$rt-toast-width: 280px;
$rt-toast-background: #fff;
$rt-toast-min-height: 48px;
$rt-toast-max-height: 800px;

$rt-color-default: color(white);
$rt-color-dark: color(black);

$rt-color-info: color(info);
$rt-color-success: color(success);
$rt-color-warning: color(warning);
$rt-color-error: color(danger);

@import '~react-toastify/scss/main.scss';
