@import '@socialbrothers/assets/styles/styles.scss';

.FilterWrapper {
  position: relative;
  margin-top: px(3);
  margin-bottom: $default-margin;

  .Toggle {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    padding: px(0.25) px(2);
    transform: translate(-50%, -50%);
    border-radius: $border-radius;
    background-color: color(gray, 200);
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }

  .Filter {
    position: relative;
    padding: px(4) px(2) 0 px(2);
    border: 1px solid color(gray, 200);
    border-radius: $border-radius;

    &--Hidden {
      height: 1px;
      margin-bottom: px(4);
      padding: 0;
      overflow: hidden;
      border-bottom: 0;
    }

    &__Row {
      position: relative;
    }

    &__Loader {
      margin: px(1);
    }

    &__Delete {
      margin-top: px(1);
      border-radius: $border-radius;
      color: color(danger);
      line-height: 48px;
      text-align: center;
      cursor: pointer;

      @include media-breakpoint-only(xs) {
        margin-top: px(-1);
        margin-bottom: px(2);
        background-color: color(danger);
        color: color(white);
      }
    }
  }
}
