@import '@socialbrothers/assets/styles/abstracts';

.Row {
	display: flex;
	flex-direction: row;
	margin: 0 px(-1);

	> * {
		margin: 0 px(1);
	}

	@include media-breakpoint-only(xs) {
		flex-direction: column;
	}
}
