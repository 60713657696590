@import '@socialbrothers/assets/styles/styles.scss';

.DebounceInput {
	position: relative;
	width: 100%;
	max-width: 300px;

	input[type='text'] {
		padding: px(1);
	}

	.Icon {
		position: absolute;
		top: 50%;
		right: px(2);
		transform: translateY(-50%);
	}
}
