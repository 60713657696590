@import '~react-quill/dist/quill.snow.css';

.quill {
	a {
		color: color(secondary);
	}

	.ql-toolbar {
		border: 0;
		border-bottom: 1px solid $input-border-color;
		border-top-left-radius: $input-border-radius;
		border-top-right-radius: $input-border-radius;

		background: $input-background-color;

		.ql-active,
		button:hover {
			.ql-stroke {
				stroke: color(secondary) !important;
			}

			.ql-fill {
				fill: color(secondary) !important;
			}
		}
	}

	.ql-container {
		border: 0;
		border-bottom-right-radius: $input-border-radius;
		border-bottom-left-radius: $input-border-radius;

		.ql-editor {
			padding: px(1.75) px(2);

			color: $body-color;
			font-family: $font-family-base;

			font-size: $input-font-size;

			p,
			ul,
			ol {
				margin-bottom: $paragraph-margin;
			}

			ul li,
			ol li {
				margin-left: 0;
				padding-left: 0;

				&::before {
					display: none;
				}
			}

			ul li {
				list-style-type: disc;
			}

			ol li {
				list-style-type: decimal;
			}

			> *:last-child {
				margin-bottom: 0;
			}
		}
	}

	.ql-tooltip {
		z-index: 20;
		box-shadow: $box-shadow-overlay;
		left: px(2) !important;
		margin: 0;
		border: 1px solid $input-border-color;
		border-radius: $input-border-radius;
		background-color: $input-border-color;

		.ql-action {
			color: color(secondary);
		}
	}
}
