@import '@socialbrothers/assets/styles/abstracts';

.AuthWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background-color: color(secondary);
	background-image: linear-gradient(180deg, color(secondary) 10%, color(secondary, dark) 100%);

	.Body {
		width: 100%;
		min-height: 400px;
		padding: px(4) px(6);
	}

	.Image {
		display: block;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $border-radius 0 0 $border-radius;
		object-fit: cover;
		filter: brightness(0.9);

		@include media-breakpoint-down(sm) {
			width: 100%;
			border-radius: $border-radius $border-radius 0 0;
		}
	}

	.Logo {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 40%;
		padding: px(2);
		border-radius: 0 $border-radius 0 $border-radius;
		background-color: color(white);
		box-shadow: $box-shadow;

		@include media-breakpoint-down(sm) {
			position: relative;
			bottom: auto;
			left: auto;
			margin: 40px auto;
			border-radius: $border-radius;
		}
	}

	.Wrapper {
		display: flex;
		flex-direction: row;
		max-width: 800px;
		margin: 0 auto;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		.Column {
			display: flex;
			position: relative;
			width: 50%;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}
