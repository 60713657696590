@import '@socialbrothers/assets/styles/abstracts';

.CheckboxInput {
	display: flex;
	flex-direction: column;
	padding: px(1.75) px(2);

	&.Inline {
		flex-direction: row;
	}
}
