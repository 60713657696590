@import '@socialbrothers/assets/styles/abstracts';

.Actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	> * {
		margin-left: px(1);

		&:first-child {
			margin-left: 0;
		}
	}
}
