@import '@socialbrothers/assets/styles/abstracts';

.Chip {
	margin-right: px(0.5);
	padding: px(0.5) px(1);
	border-radius: 32px;
	background-color: color(primary);
	color: color(white);
	text-align: center;

	&:last-child {
		margin-right: 0;
	}
}
