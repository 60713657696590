@import '@socialbrothers/assets/styles/styles.scss';

.Pagination {
	display: flex;
	align-items: center;
	white-space: nowrap;

	.Page {
		display: flex;
		align-items: center;
		margin-right: px(2);
		margin-left: px(2);
	}

	select {
		margin: 0 px(1);
		padding: px(1);
		text-align: center;
	}

	@include media-breakpoint-only(xs) {
		justify-content: space-between;
		width: 100%;
		margin-bottom: px(2);
	}
}
