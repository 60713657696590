@import '@socialbrothers/assets/styles/styles.scss';

.NotFound {
	position: relative;
	margin: auto !important;
	text-align: center;

	.Title {
		color: color(primary);
		font-size: 100px;
		font-weight: $font-weight-base;
		line-height: 1;
	}

	.Subtitle {
		margin-bottom: spacing(lg);
		font-size: $font-size-lg;
	}
}
