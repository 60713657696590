@import '@socialbrothers/assets/styles/abstracts';

.Button {
	display: inline-block;
	position: relative;
	padding: $button-padding;
	border-radius: $button-border-radius;
	color: $button-font-color;
	font-family: $font-family-base;
	font-size: $button-font-size;

	@include transition(background-color);

	&__Loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
	}

	&__Icon {
		margin-right: px(1);
	}

	&__Content {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;

		> *:last-child {
			margin-right: 0;
		}
	}

	&--Disabled {
		opacity: 0.5;
		cursor: not-allowed !important;
	}

	&--large {
		padding: $button-lg-padding;
		font-size: $button-lg-font-size;
		font-size: $button-lg-font-size;
	}

	&--small {
		padding: $button-sm-padding;
		font-size: $button-sm-font-size;
	}

	&--Loading {
		.Button__Loader {
			opacity: 1;
		}

		.Button__Content {
			opacity: 0;
		}
	}

	@each $name, $color in $theme-colors {
		&.Color#{$name} {
			&.Button--Link {
				padding-right: 0;

				padding-left: 0;
				border-color: transparent;
				background-color: transparent;
				color: map-get($color, 'default');
			}

			&:not(.Button--Link) {
				border: 1px solid map-get($color, 'default');
				background-color: map-get($color, 'default');

				&:hover {
					background-color: map-get($color, 'dark');
					color: color(white);
					text-decoration: none;
				}

				&:focus {
					box-shadow: 0 0 0 0.2rem rgba(map-get($color, 'default'), 0.3);
				}
			}
		}
	}
}
