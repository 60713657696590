@import '@socialbrothers/assets/styles/abstracts';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  min-height: 70px;
  padding: 0 $body-spacing;
  border-bottom: 1px solid color(gray, 300);
  background-color: color(white);
  box-shadow: $box-shadow;

  &__Avatar {
    display: inline-flex;
    align-items: center;
  }

  &__AvatarCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: color(secondary);
    color: color(white);
  }

  &__Email {
    margin-left: px(1);

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}
