@import '@socialbrothers/assets/styles/abstracts';

.KPI {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: spacing();
	padding: px(2) $default-padding;
	border: 1px solid color(gray, 300);
	border-radius: $border-radius;
	background-color: color(white);
	box-shadow: $box-shadow;
}

.Title {
	font-size: $font-size-xs;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

.Body {
	width: 100%;
}

.Value {
	display: flex;
	align-items: center;
	color: color(black);
	font-size: $font-size-lg;
	font-weight: $font-weight-bold;
	white-space: nowrap;
}

.Progress {
	margin-right: px(2);
	margin-left: px(2);
}

.Icon {
	margin-left: auto;
	color: color(gray, 300);
	font-size: $font-size-xxl;
}
